import React from 'react';
import {createContext, useContext, useState} from 'react';
import { useSnackbar } from '../../Contexts/SnackbarContext/SnackbarContext';
import FetchModule from '../../Modules/FetchModule';
const fetchData = new FetchModule();

const FileUploaderContext = createContext<FileUploaderProps | null>(null)
interface FileUploaderProps {
    setFile: (name: any) => void, // Does validation of inputed files on select from an event
    setSelectedFiles(name: any): void, // Updates the files directly
    selectedFiles: any | null | any[],
    canCreate: boolean, 
    setCanCreate: (name: boolean) => void,
    acceptsFileType: string
    uploading: boolean,
    setUploading: (name: boolean) => void,
    handleDrop: (event: any) => void,
    dragOver: boolean,
    setDragOver: (name: boolean) => void,
    uploadToBackend: () => void,
    endpoint: string
}

type FileUploaderProviderProps = {
    children?: React.ReactNode;
    endpoint: string;
    accepts?: string;
    autoUpload?: boolean;
    maxFiles?: number;
}

export const FileUploaderProvider = ({children, endpoint, accepts: acceptsFileType, maxFiles} : FileUploaderProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();
    const [dragOver, setDragOver] = React.useState(false);

    const [selectedFiles, setSelectedFiles] = useState<any | any[] | null>(null); 
    const [canCreate, setCanCreate] = useState(false);
    const [uploading, setUploading] = useState(false);

    // Sets defaults of props when not provided
    acceptsFileType = acceptsFileType || 'any';
    maxFiles = maxFiles || 1;

    async function uploadToBackend() {
        if (!uploading) {
            setUploading(true)
            const response = await fetchData.filesFormData(endpoint, "POST", selectedFiles);
            if (response.ok) {
                newSnackbarMessage("File(s) uploaded successfully", "success");
                setSelectedFiles(null);
            } else {
                newSnackbarMessage("Error uploading file(s)", "error");
            }

            setUploading(false)
        }
    }

    function verifyFileLength(files: any) {
        if (maxFiles && files.length > maxFiles) {
            newSnackbarMessage("Too many files selected, max of " + maxFiles.toString(), "error");
            return false;
        } 
        return true;
    }
    function verifyFileType(file: any) {
        if (acceptsFileType && (acceptsFileType == "any" || file.type == acceptsFileType)) return true;
        
        return false;
    }

    function setFile(e: any) {
        handleFileData(e.target.files)
    }
    const handleDrop = (event: any) => {
        handleFileData(event.dataTransfer.files);
      };

    const handleFileData = (files: any) => {
        if (!verifyFileLength(files)) return;
        const formData = new FormData();
        let notCorrectLength = 0;
        let correctLength = 0;
        for (let i = 0; i < files.length; i++) {
            if (verifyFileType(files[i])) {
                formData.append('file', files[i] as Blob);
                correctLength++;
            }else {
                notCorrectLength++;
            }
        }
        if (notCorrectLength > 0) {
            newSnackbarMessage(notCorrectLength.toString() +" files are not of the correct file type", "error");
        }
        if (correctLength > 0) {
            setSelectedFiles(formData);
        }
        setDragOver(false);
    }



    const contextValues: FileUploaderProps = {
        endpoint,
        setFile, selectedFiles, canCreate, setCanCreate, acceptsFileType, uploading, setUploading, handleDrop, dragOver, setDragOver, setSelectedFiles, uploadToBackend
    }
    return (
        <FileUploaderContext.Provider value={contextValues}>
            {children}
        </FileUploaderContext.Provider>
        )
}

export function useFileUploader() {
    const context = useContext(FileUploaderContext);
    if (!context) {
        throw new Error("Invalid FileUploaderContext, must be wrapped in a FileUploaderContext")
    }
    return context;
}