// Flags set for the user permissions
export enum PermissionFlagKey {
    COMMISSION_FLAG = "commissionFlag",
    REPACK_FLAG = "repackFlag",
    ADMIN_FLAG = "adminFlag",
    DEV_FLAG = "devFlag",
    MARKETING_FLAG = "marketingFlag",
    CARDS_FLAG = "cardsFlag",
    LOGISTICS_FLAG = "logisticsFlag"
}


export enum PermissionRoles {
    COMMISSION = "Commission",
    REPACK = "Repack",
    ADMIN = "Admin",
    DEV = "Dev",
    MARKETING = "Marketing",
    CARDS = "Cards",
    LOGISTICS = "Logistics"
}