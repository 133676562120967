import React from "react";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import { useFeatureFlags } from "../Contexts/FeatureFlagContexts/FeatureFlagContext";
import { PermissionRoles } from "../Components/CommonUtility/PermissionFlagsEnum";
import { useUser } from "../Components/CommonUtility/UserContext";
const Homepage = () => {
  var { flags } = useFeatureFlags();
  const { hasPermission } = useUser();

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        maxHeight: "90vh",
        overflowY: "auto",
        padding: 5,
      }}
    >
      {flags["cards"] && hasPermission(PermissionRoles.CARDS) && (
        <>
          <Card
            sx={{
              flexBasis: "calc(33.333% - 20px)",
              height: 100,
              margin: 3,
              boxSizing: "border-box",
            }}
            variant="outlined"
            style={{ cursor: "pointer" }}
          >
            <CardActionArea component={Link} to="/dashboard/cards">
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Single Cards Page
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Navigate to the Single Cards spreadsheet
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{
              flexBasis: "calc(33.333% - 20px)",
              height: 100,
              margin: 3,
              boxSizing: "border-box",
            }}
            variant="outlined"
            style={{ cursor: "pointer" }}
          >
            <CardActionArea component={Link} to="/dashboard/sku/correction">
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Sku Correction Page
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Correct bad skus
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </>
      )}
      {flags["dearsync"] && (
                <Card
                sx={{
                  flexBasis: "calc(33.333% - 20px)",
                  height: 100,
                  margin: 3,
                  boxSizing: "border-box",
                }}
                variant="outlined"
                style={{ cursor: "pointer" }}
              >
                <CardActionArea component={Link} to="/dashboard/cards/correction">
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Card Sync Correction Page
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Links to the Card Sync Correction Page
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
      )

      }
      {flags["settings"] && (
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/settings">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Settings Page
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Navigate to Settings
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {flags["repack"] && hasPermission(PermissionRoles.REPACK) &&(
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/repack/view">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                View Repacks
              </Typography>
              <Typography variant="body2" color="text.secondary">
                View Repacks
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {flags["barcode"] && (
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/barcode">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Missing Barcode Search
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Find Missing Barcode
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {flags["showhosts"] && hasPermission(PermissionRoles.COMMISSION) && (
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/showHosts">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Show Host Management Page
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage active show hosts
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {flags["shows"] && hasPermission(PermissionRoles.COMMISSION) && (
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/shows">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Show Management Page
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage shows
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {flags["ticker"] && hasPermission(PermissionRoles.MARKETING) && (
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/ticker">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Ticker Page
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage Tickers
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}

      {flags["ticker"] && hasPermission(PermissionRoles.DEV) && (
        <Card
          sx={{
            flexBasis: "calc(33.333% - 20px)",
            height: 100,
            margin: 3,
            boxSizing: "border-box",
          }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/stream-pc">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Stream PC Management
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage Stream PC's
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {/* for the bit */}
      {/* <div
        style={{
          flexBasis: "calc(33.333% - 20px)",
          height: 300,
          width: "100%",
          margin: 3,
          boxSizing: "border-box",
        }}
        variant="outlined"
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/y0sF5xhGreA?si=cXiatS9sAr8X8HPC"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div> */}
    </div>
  );
};

export default Homepage;
