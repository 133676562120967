import React from "react";
import { Card, CardContent, Grid, Typography, Box, Button } from "@mui/material";
import {
  Warning as WarningIcon,
  CancelOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";

interface GroupedData {
  count: number;
}

const InfoGrid = () => {
  const { seriesList = [] } = useBuildRepacks();

  const renderIcon = (value: number) => {
    if (value > 20) {
      return <CheckCircleOutline color="success" />;
    } else if (value > 10) {
      return <WarningIcon color="warning" />;
    } else {
      return <CancelOutlined color="error" />;
    }
  };

  const groupedByBoxType = seriesList.reduce<Record<string, GroupedData>>((acc, series) => {
    series.boxes?.forEach(box => {
      const boxTypeName = box.repackBoxType?.name || 'Unknown';
      
      if (!acc[boxTypeName]) {
        acc[boxTypeName] = {
          count: 0
        };
      }
      
      acc[boxTypeName].count += 1;
    });
    
    return acc;
  }, {});
  
  const groupedByLocation = seriesList.reduce<Record<string, GroupedData>>((acc, series) => {
    const location = series.location || 'Unknown';
    
    if (!acc[location]) {
      acc[location] = {
        count: 0
      };
    }
  
    acc[location].count += series.boxes?.length || 0;
    
    return acc;
  }, {});
  
  const totalBoxes = seriesList.reduce((acc, series) => acc + (series.boxes?.length || 0), 0);

  const renderGridItem = (
    label: string,
    value: number
  ) => (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center">
            <Box className="icon-container" marginRight={2}>
              {renderIcon(value)}
            </Box>
            <Box className="grid-item">
              <Typography variant="h6">{label}</Typography>
              <Typography variant="subtitle1">{value}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );

  const handleCopyInfo = () => {
    const copyText = [
      `Total Boxes: ${totalBoxes}`,
      '\nBy Box Type:',
      ...Object.entries(groupedByBoxType || {}).map(
        ([type, data]) => `${type}: ${data.count} boxes`
      ),
      '\nBy Location:',
      ...Object.entries(groupedByLocation || {}).map(
        ([location, data]) => `${location}: ${data.count} boxes`
      )
    ].join('\n');

    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <CollapsiblePaper
      title="Repack Info"
      openTitle="View Repack Info"
      closeTitle="Hide Repack Info"
    >
      <Grid container spacing={3}>
        {/* Total Boxes */}
        {renderGridItem("Total Boxes", totalBoxes)}

        {/* Box Type Counts */}
        {Object.entries(groupedByBoxType || {}).map(([type, data]) => (
          <React.Fragment key={type}>
            {renderGridItem(`${type} Boxes`, data.count)}
          </React.Fragment>
        ))}

        {/* Location Counts */}
        {Object.entries(groupedByLocation || {}).map(([location, data]) => (
          <React.Fragment key={location}>
            {renderGridItem(`Location: ${location}`, data.count)}
          </React.Fragment>
        ))}
      </Grid>

      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleCopyInfo}>
          Copy All Info
        </Button>
      </Box>
    </CollapsiblePaper>
  );
};

export default InfoGrid;