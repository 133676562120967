import * as React from 'react';
import {createContext, useContext, useState} from 'react';

import { useNavigate } from 'react-router-dom';
import usePaginationFiltering, { PaginationModel, SortModel } from '../../Components/CommonUtility/usePaginationFiltering';
import { useSnackbar } from '../../Contexts/SnackbarContext/SnackbarContext';
import { ICardSkuDearCollision } from './ICardSkuDearCollision';
import { GridFilterModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { DearSkuCollissionUpdate } from '../../DearContent/DearAPICalls';
import { useUser } from '../../Components/CommonUtility/UserContext';

const CardSyncCorrectionContext = createContext<CardSyncCorrectionContextProps | null>(null);
interface CardSyncCorrectionContextProps {

    cardDearCollisions: Array<ICardSkuDearCollision>,
    setCardDearCollisions: (hosts: Array<ICardSkuDearCollision>) => void,
    loading: boolean,
    error: boolean,
    navigateToPayPeriod: (object: any) => void,
    paginationModel: PaginationModel, setPaginationModel: (obj: PaginationModel) => void, totalRows: number, 
    filteringModel: GridFilterModel, setFilteringModel: (obj: GridFilterModel) => void,
    sortModel: SortModel, setSortModel: (obj: any) => void, fetching: boolean,
    gridApiRef: React.MutableRefObject<GridApiPremium>,
    verifySync: (collision: ICardSkuDearCollision, approved: boolean) => void
}

type CardSyncCorrectionProviderProps = {
    children?: React.ReactNode;
}

export const CardSyncCorrectionProvider = ({children} : CardSyncCorrectionProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();
    const navigate = useNavigate();

    const defaultPaginationModel: PaginationModel = {page: 0, pageSize: 100} 
    const defaultFilteringModel = {items: [], quickFilterValues: []}
    const defaultSortModel: SortModel = null
    const gridApiRef = useGridApiRef();
    const user = useUser();
    const {
        data: cardDearCollisions, 
        setData: setCardDearCollisions, 
        loading: loading, 
        error: error,
        paginationModel, setPaginationModel,
        filteringModel, setFilteringModel,
        sortModel, setSortModel,
        fetching,
        totalRows
    }: {
        data: Array<ICardSkuDearCollision>, setData: any, loading: boolean, error: any, 
        paginationModel: PaginationModel, setPaginationModel: (obj: PaginationModel) => void, totalRows: number, 
        filteringModel: GridFilterModel, setFilteringModel: (obj: GridFilterModel) => void,
        sortModel: SortModel, setSortModel: (obj: any) => void, fetching: boolean
    } = usePaginationFiltering("/Card/dearSkuCollisions", null, defaultPaginationModel, defaultFilteringModel, defaultSortModel);

    const [canCreate, setCanCreate] = useState(true);

    async function verifySync(collision: ICardSkuDearCollision, approved: boolean) {
        if (canCreate) {
            setCanCreate(false)
            try {
                const newCollision = {...collision, syncVerified: approved}
                const response = await DearSkuCollissionUpdate(newCollision, user)
                if (response.status == 200) {
                    newSnackbarMessage("Successfully updated Dear Card Sync Collision", 'success');
                    
                    gridApiRef.current.updateRows([{ id: newCollision.id, syncVerified: approved }]);
                    const updatedCollisions = cardDearCollisions.map((x) => {
                        if (x.id === newCollision.id) {
                            return {...x, syncVerified: approved}
                        }
                        return x
                    })
                    setCardDearCollisions(updatedCollisions)
                } else {
                    newSnackbarMessage("Error updating Dear Card Sync Collision, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error updating Dear Card Sync Collision, connection invalid please try again later", 'error');
            }
            setCanCreate(true)
        }
    }


    const contextValues = {
        cardDearCollisions, setCardDearCollisions,
        loading, error,
        navigateToPayPeriod: (object: any) => navigate(`/payperiod/${object.id}`),
        paginationModel, setPaginationModel,
        filteringModel, setFilteringModel,
        sortModel, setSortModel,
        fetching,
        gridApiRef,
        totalRows,
        verifySync
    }
    return (
        <CardSyncCorrectionContext.Provider value={contextValues}>
            {children}
        </CardSyncCorrectionContext.Provider>
        )
}

export function useCardSyncCorrectionContext() {
    const context = useContext(CardSyncCorrectionContext);
    if (!context) {
        throw new Error("Invalid PayPeriodContext, must be wrapped in a PayPeriodProvider")
    }
    return context;
}
