import { GridColDef } from '@mui/x-data-grid-pro';
import { RepackItem } from '../../../../Types/RepackItem';

export const columns: GridColDef[] = [
  {
    field: "rowNumber",
    headerName: "#",
    width: 50,
    renderCell: (params) =>
      params.api.getSortedRowIds().indexOf(params.id) + 1,
  },
  {
    field: "repackBoxType",
    headerName: "Repack Type",
    width: 100,
    valueGetter: (params) => params.row.repackBoxType?.name || "",
  },
  {
    field: "itemCount",
    headerName: "Items",
    width: 50,
    valueGetter: (params) => params.row.repackItems?.length || 0,
  },
  {
    field: "comp",
    headerName: "Comp",
    width: 100,
    type: "number",
    valueGetter: (params) => {
      const items = params.row.repackItems || [];
      return items.reduce((acc: number, item: RepackItem) => {
        if (item.repackCard?.comp) {
          return acc + Number(item.repackCard.comp);
        }
        if (item.repackTicket?.cost) {
          return acc + Number(item.repackTicket.cost);
        }
        return acc;
      }, 0);
    },
    valueFormatter: (params) => {
      if (params.value == null) return "";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.value);
    },
  },
  {
    field: "totalCost",
    headerName: "Total Cost",
    width: 100,
    type: "number",
    valueGetter: (params) => {
      const items = params.row.repackItems || [];
      return items.reduce((acc: number, item: RepackItem) => {
        if (item.repackCard?.totalCost) {
          return acc + Number(item.repackCard.totalCost);
        }
        if (item.repackTicket?.cost) {
          return acc + Number(item.repackTicket.cost);
        }
        return acc;
      }, 0);
    },
    valueFormatter: (params) => {
      if (params.value == null) return "";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.value);
    },
  },
  {
    field: "difference",
    headerName: "Difference",
    width: 100,
    type: "number",
    valueGetter: (params) => {
      const items = params.row.repackItems || [];
      return items.reduce((acc: number, item: RepackItem) => {
        if (item.repackCard) {
          const comp = Number(item.repackCard.comp || 0);
          const cost = Number(item.repackCard.totalCost || 0);
          return acc + (comp - cost);
        }
        return acc;
      }, 0);
    },
    valueFormatter: (params) => {
      if (params.value == null) return "";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.value);
    },
  },
  {
    field: "containsSpecialItems",
    headerName: "Special Items",
    width: 100,
    valueGetter: (params) => {
      const items = params.row.repackItems || [];
      return items.some((item: RepackItem) => {
        const typeName = (item.repackItemType?.name || "").trim().toLowerCase();
        return typeName !== "" && typeName !== "card" && typeName !== "ticket";
      })
        ? "Yes"
        : "No";
    },
  }
];