import React from 'react';
import { DataGridPro, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import BoxGridToolbar from './BoxGridToolbar';
import DetailPanel from '../DetailPanel/DetailPanel';
import {columns} from './columns'
import { useBuildRepacks } from '../../../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext';
import { BoxActions, ItemActionsType } from '../ItemGrid/types';


export interface BoxGridProps {
  expandedRowIds: GridRowId[];
  onRowExpandChange: (ids: GridRowId[]) => void;
  onBoxSelection: (id: GridRowId) => void;
  boxActions: BoxActions;
  itemActions: ItemActionsType;
  seriesId: string;
}

const BoxGrid: React.FC<BoxGridProps> = ({ expandedRowIds, onRowExpandChange, onBoxSelection, boxActions, itemActions, seriesId }) => {
  const { boxes = [] } = useBuildRepacks();

  const handleRowClick = (params: GridRowParams) => {
    onBoxSelection(params.id);
  };

  const getDetailPanelContent = () => {
    return (
      <DetailPanel
        itemActions={itemActions}
        boxActions={boxActions}
      />
    );
  };

  return (
    <Box sx={{ height: 'auto', minHeight: '400px', width: '100%' }}>
      <DataGridPro
        getRowId={(row) => row.id}
        rows={boxes}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 250}
        detailPanelExpandedRowIds={expandedRowIds}
        onDetailPanelExpandedRowIdsChange={onRowExpandChange}
        disableMultipleRowSelection={true}
        onRowClick={handleRowClick}
        processRowUpdate={(newRow) => boxActions.handleUpdateBox(newRow)}
        onProcessRowUpdateError={(error) => {
          console.error("Error processing box update:", error);
        }}
        components={{
          Toolbar: () => (
            <BoxGridToolbar
              boxActions={boxActions}
              seriesId={seriesId}
            />
          ),
        }}
      />
    </Box>
  );
};

export default BoxGrid;

