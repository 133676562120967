import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useCardSyncCorrectionContext } from './CardSyncCorrectionContext';
import CardSyncCorrectionDataGridToolbar from './CardSyncCorrectionDataGridToolbar';
import { ICardSkuDearCollision } from './ICardSkuDearCollision';
import { Button, Typography } from '@mui/material';
type ShowsDataGridProps = {
    children?: React.ReactNode;
}
/* 
    CardSyncCorrectionDataGrid component must be wrapped by a CardSyncCorrectionProvider to work properly
*/
dayjs.extend(utc)

function GridLinkCell(
    props: GridRenderCellParams & { href: string, display: string },
    ) {
      if (props.value == null) {
        return null;
      }
      return (
        <Typography sx={{textAlign: "left", alignContent:"center", height: "100%", width: "100%"}}>
            <a href={props.href} target='_blank' rel="noreferrer">
                {props.display} 
            </a>
        </Typography>
      );
    }

const CardSyncCorrectionDataGrid = ({} : ShowsDataGridProps)  => {
    
    const {        
        cardDearCollisions, 
        loading, 
        error,
        paginationModel, setPaginationModel,
        setFilteringModel,
        setSortModel,
        fetching,
        gridApiRef,
        totalRows,
        verifySync,
    } = useCardSyncCorrectionContext();

    function verifySyncButton(id: any, row: ICardSkuDearCollision) {
        if (row.syncVerified && id != null) {
            return <Button onClick={() => verifySync(row, false)}>Remove Sync</Button>
        } 
        return <Button onClick={() => verifySync(row, true)}>Approve Sync</Button>
    }

    const columns: GridColDef[] = [
        { field: 'inventoryId', headerName: 'Inventory ID', width: 100, type: 'number',
            valueGetter: (params, row) => {
                return row.card?.inventoryId
            },
        },
        { field: 'sku', headerName: 'Card Sku', width: 300, type: 'string',
            valueGetter: (params, row) => {
                return row.card?.sku
            },
        },
        { field: 'timeOfSync', headerName: 'Time of Sync', width: 200, type: 'dateTime',
            valueGetter: (params) => {
                return new Date(params);
            }
        },
        {field: 'dearFromProductGuid', headerName: 'Dear From Product', width: 200, type: 'string',
            renderCell: (params) => <GridLinkCell href={"https://inventory.dearsystems.com/Product#" +params.row.dearFromProductGuid} display="Dear From Product" {...params} />,
        },
        {field: 'dearToProductGuid', headerName: 'Dear To Product', width: 200, type: 'string',
            renderCell: (params) => <GridLinkCell href={"https://inventory.dearsystems.com/Product#" +params.row.dearToProductGuid} display="Dear To Product" {...params} />,
        },

        {field: 'syncVerified', headerName: 'Sync Approved', width: 200, type: 'boolean'},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            filterable: false,
            sortable: false,
            getActions: ({ id, row }) => {
                return [
                    verifySyncButton(id, row)
                ];
              },
        }
    ];
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error Loading</div>

    return(
        <>
           <DataGridPremium 
                sx={{height: '500px'}}
                rows={cardDearCollisions}
                columns={columns}
                apiRef={gridApiRef}
                slots={{
                    toolbar: CardSyncCorrectionDataGridToolbar
                }}
                initialState={{
                    pagination: {paginationModel: paginationModel},
                }}
                paginationMode="server"
                pagination
                sortingMode="server"
                pageSizeOptions={[100, 250, 500]}
                rowCount={totalRows}
                onPaginationModelChange={(newPaginationModel) => {
                    setPaginationModel(newPaginationModel)
                }}
                loading={fetching}
                filterMode="server"
                onFilterModelChange={(newFilterModel) => {
                    setFilteringModel(newFilterModel)
                }}
                onSortModelChange={(newSortModel) => {
                    setSortModel(newSortModel)
                }}
           />
        </>
    )
}
  
  
  export default CardSyncCorrectionDataGrid;
  