import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  GridColDef,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  DataGrid,
} from "@mui/x-data-grid";
import { DataGridPro, GridToolbarColumnsButton } from "@mui/x-data-grid-pro";
import { GridRowId, GridSortModel } from "@mui/x-data-grid";
import {
  Typography,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { BoxDetailPanel } from "./BoxDetailPanel";

interface CustomToolbarProps {
  onRefresh: () => void;
  onAdd?: () => void;
  showAddButton?: boolean;
}

function CustomGridToolbar({ onRefresh }: CustomToolbarProps) {
  return (
    <GridToolbarContainer sx={{ p: 2, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
          }}
        />
        <Tooltip title="Refresh data">
          <IconButton onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </GridToolbarContainer>
  );
}

const RepackSeriesGrid = () => {
  const {
    seriesList,
    showPaginationModel,
    setSeriesPaginationModel,
    totalSeriesRows,
    sortModel,
    setSortModel,
    fetching,
    filteringModel,
    setFilteringModel,
    setBox,
    setSelectedBox,
  } = useBuildRepacks();

  const [seriesExpandedRowIds, setSeriesExpandedRowIds] = useState<GridRowId[]>(
    []
  );
  const [boxExpandedRowIds, setBoxExpandedRowIds] = useState<GridRowId[]>([]);

  const boxColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "serialNumber", headerName: "Serial Number", flex: 1 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      valueGetter: (params) => params.row.repackBoxType?.name,
    },
    { field: "isStaged", headerName: "Staged", flex: 0.5, type: "boolean" },
    { field: "repackCaseId", headerName: "Case ID", flex: 0.5 },
    {
      field: "itemCount",
      headerName: "Items",
      flex: 0.5,
      valueGetter: (params) =>
        params.row.items?.length || params.row.repackItems?.length || 0,
    },
  ];

  const itemColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => {
        const item = params.row;
        const cardName = item.repackCard?.name || "";
        const ticketName = item.repackTicket?.repackTicketType?.name || "";

        if (item.repackCard && item.repackTicket) {
          return `${cardName} (with ${ticketName})`;
        }

        return cardName || ticketName || "Unknown Item";
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      valueGetter: (params) => params.row.repackItemType?.name,
    },
    { field: "location", headerName: "Location", flex: 1 },
  ];

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", type: "number", flex: 0.25 },
    { field: "productId", headerName: "Series Name", flex: 1 },
    {
      field: "BoxCount",
      headerName: "# of Boxes",
      type: "number",
      flex: 0.5,
      valueGetter: (params) =>
        params.row.repackSeriesTotalsView?.totalBoxCount ?? 0,
    },
    {
      field: "synced",
      headerName: "Synced",
      flex: 0.5,
      type: "boolean",
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "isStaged",
      headerName: "Staged",
      flex: 0.5,
      type: "boolean",
    },
  ];

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const transformedSortModel = newSortModel.map((sort) => ({
      field: sort.field,
      sort: sort.sort?.toLowerCase(),
    }));
    setSortModel(transformedSortModel);
  };

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    const transformedFilterModel = {
      items: newFilterModel.items.map((filter) => ({
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
      })),
      quickFilterValues: newFilterModel.quickFilterValues || [],
    };
    setFilteringModel(transformedFilterModel);
  };

  const handleRefresh = useCallback(() => {
    setFilteringModel({ items: [], quickFilterValues: [] });
  }, [setFilteringModel]);

  const handleBoxClick = useCallback(
    (boxId: number) => {
      const series = seriesList.find((s) =>
        s.boxes?.some((b) => b.id === boxId)
      );
      const clickedBox = series?.boxes?.find((b) => b.id === boxId);
      if (clickedBox) {
        setSelectedBox(clickedBox);
        setBox(clickedBox);
      }
    },
    [seriesList, setSelectedBox, setBox]
  );

  const handleBoxExpandChange = useCallback(
    (newIds: GridRowId[]) => {
      setBoxExpandedRowIds(newIds);
      if (newIds.length > 0) {
        const boxId = Number(newIds[newIds.length - 1]);
        handleBoxClick(boxId);
      }
    },
    [handleBoxClick]
  );

  const SeriesDetailPanel = useCallback(
    (params: any) => {
      const rowBoxes = params.row.boxes || [];
      const prizes = params.row.repackTicketPrizes || [];

      return (
        <Box sx={{ p: 2 }}>
          {/* Boxes Section */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                Boxes in {params.row.productId} ({rowBoxes.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ height: 400, width: "100%" }}>
                <DataGridPro
                  rows={rowBoxes}
                  columns={boxColumns}
                  density="compact"
                  getDetailPanelContent={(params) => (
                    <BoxDetailPanel
                      row={params.row}
                      itemColumns={itemColumns}
                    />
                  )}
                  getDetailPanelHeight={() => 450}
                  detailPanelExpandedRowIds={boxExpandedRowIds}
                  onDetailPanelExpandedRowIdsChange={handleBoxExpandChange}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0
                      ? "even-row"
                      : "odd-row"
                  }
                  sx={{
                    "& .MuiDataGrid-row.even-row": {
                      backgroundColor: "#f5f5f5",
                    },
                    "& .MuiDataGrid-row.odd-row": {
                      backgroundColor: "#ffffff",
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Prizes Section */}
          {prizes.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  Available Prizes ({prizes.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ height: 300, width: "100%" }}>
                  <DataGrid
                    rows={prizes}
                    columns={[
                      { field: "id", headerName: "ID", width: 100 },
                      { field: "name", headerName: "Name", flex: 1 },
                      {
                        field: "cost",
                        headerName: "Cost",
                        width: 150,
                        valueGetter: (params) =>
                          params.row.cost ? `$${params.row.cost}` : "Unknown",
                      },
                      {
                        field: "isClaimed",
                        headerName: "Status",
                        width: 150,
                        valueGetter: (params) =>
                          params.row.isClaimed ? "Claimed" : "Available",
                      },
                    ]}
                    density="compact"
                    disableRowSelectionOnClick
                    hideFooter={prizes.length <= 10}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      );
    },
    [boxColumns, itemColumns, boxExpandedRowIds, handleBoxExpandChange]
  );

  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGridPro
        initialState={{
          pagination: { paginationModel: showPaginationModel },
          sorting: { sortModel: sortModel || [] },
          filter: { filterModel: filteringModel },
        }}
        slots={{
          toolbar: CustomGridToolbar,
        }}
        slotProps={{
          toolbar: {
            onRefresh: handleRefresh,
            showAddButton: true,
          },
        }}
        rows={seriesList}
        columns={columns}
        rowCount={totalSeriesRows}
        paginationModel={showPaginationModel}
        onPaginationModelChange={setSeriesPaginationModel}
        onDetailPanelExpandedRowIdsChange={(newIds) =>
          setSeriesExpandedRowIds(newIds)
        }
        detailPanelExpandedRowIds={seriesExpandedRowIds}
        getDetailPanelContent={SeriesDetailPanel}
        getDetailPanelHeight={() => 450}
        density="compact"
        paginationMode="server"
        pagination
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        disableColumnFilter={false}
        pageSizeOptions={[25, 100, 500]}
        loading={fetching}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
        }
        sx={{
          "& .MuiDataGrid-row.even-row": {
            backgroundColor: "#f5f5f5",
          },
          "& .MuiDataGrid-row.odd-row": {
            backgroundColor: "#ffffff",
          },
        }}
      />
    </div>
  );
};

export default RepackSeriesGrid;
