import React, { useState } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Grid, Button } from '@mui/material';
import BoxTypeSelect from '../BoxTypeSelect/BoxTypeSelect';
import { useBuildRepacks } from '../../../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext';
import { BoxActions } from '../ItemGrid/types';


export interface BoxGridToolbarProps {
  boxActions: Pick<BoxActions, 'handleCreateBox' | 'handleAddToCase' | 'isAddingToCase'>;
  seriesId: string;
}

const BoxGridToolbar: React.FC<BoxGridToolbarProps> = ({
  boxActions,
  seriesId,
}) => {
  const { boxTypes, selectedCase, selectedBox } = useBuildRepacks();
  
  const [selectedBoxType, setSelectedBoxType] = useState<number>(
    boxTypes[0]?.id || 1
  );

  return (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item style={{ flexGrow: 1 }}>
          <BoxTypeSelect 
            boxTypes={boxTypes}
            selectedBoxType={selectedBoxType}
            onBoxTypeChange={setSelectedBoxType}
          />
        </Grid>
        <Grid item>
          <Button 
            variant="text" 
            onClick={() => boxActions.handleCreateBox(selectedBoxType, seriesId)}
          >
            Add New Box
          </Button>
          <Button
            variant="text"
            onClick={() => selectedBox && boxActions.handleAddToCase(selectedBox, selectedCase)}
            disabled={boxActions.isAddingToCase || !selectedCase || !selectedBox}
          >
            {boxActions.isAddingToCase ? "Adding to Case..." : "Add To Selected Case"}
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default BoxGridToolbar;