import React from "react";
import { GridColDef, GridPreProcessEditCellProps, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import DataGridAutocomplete from "../../../../../../Components/Autocomplete/DatagridAutocomplete";
import { ItemActionsType } from "./types";
import { formatCurrency } from "./formatters";
import { RepackItemType } from "../../../../Types/RepackItemType";
import ItemActions from "./ItemActions";

const handleTypeChange = async (
  params: GridRenderEditCellParams,
  newValue: string | RepackItemType | null, 
  itemTypes: RepackItemType[],
  actions: ItemActionsType
) => {

  if (!newValue) {
    return;
  }

  try {
    let updatedType: RepackItemType | null = null;

    if (typeof newValue === "string") {
      if (newValue.startsWith('Add "')) {
        const typeName = newValue.slice(5, -1);
        updatedType = await actions.handleCreateItemType(typeName);
      } else {
        updatedType = itemTypes.find(type => type.name === newValue) || null;
      }
    } else if (typeof newValue === "object") {
      updatedType = newValue as RepackItemType;
    }

    if (updatedType) {
      const updatedRow = {
        ...params.row,
        repackItemType: updatedType,
        repackItemTypeId: updatedType.id,
      };

      const result = await actions.handleUpdateItem(updatedRow);
      
      if (result) {
        
        await params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value: updatedType.name,
        }, true);
        
        params.api.updateRows([{ 
          ...result,
          repackItemType: updatedType,
          repackItemTypeId: updatedType.id
        }]);
      } else {
      }
    } else {
    }
  } catch (error) {
    console.error('Error in handleTypeChange:', error);
  }
};

export const createColumns = (
  itemTypes: RepackItemType[],
  actions: ItemActionsType
): GridColDef[] => [
  {
    field: "slotNumber",
    headerName: "Slot",
    width: 80,
    editable: true,
    type: "number",
    valueGetter: (params) =>
      params.row.slotNumber ||
      params.api.getSortedRowIds().indexOf(params.id) + 1,
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
    valueGetter: (params) => {
      const { repackCard, repackTicket } = params.row;
      const cardName = repackCard?.name || "";
      const ticketName = repackTicket?.repackTicketType?.name || "";

      return repackCard && repackTicket
        ? `${cardName} (with ${ticketName})`
        : cardName || ticketName || "Unknown Item";
    },
  },
  {
    field: "cardComp",
    headerName: "Card Comp",
    width: 100,
    valueGetter: (params) => Number(params.row.repackCard?.comp || 0),
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: "cardCost",
    headerName: "Card Cost",
    width: 100,
    valueGetter: (params) => Number(params.row.repackCard?.totalCost || 0),
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: "cardDifference",
    headerName: "Card Diff",
    width: 100,
    valueGetter: (params) => {
      const comp = Number(params.row.repackCard?.comp || 0);
      const cost = Number(params.row.repackCard?.totalCost || 0);
      return comp - cost;
    },
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: "ticketCost",
    headerName: "Ticket Cost",
    width: 100,
    valueGetter: (params) => Number(params.row.repackTicket?.cost || 0),
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: "totalValue",
    headerName: "Total Value",
    width: 100,
    valueGetter: (params) => {
      const cardComp = Number(params.row.repackCard?.comp || 0);
      const ticketCost = Number(params.row.repackTicket?.cost || 0);
      return cardComp + ticketCost;
    },
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    editable: true,
    valueGetter: (params) => {
      const type = itemTypes.find(
        (type) => type.id === params.row.repackItemTypeId
      );
      return type?.name || "Unknown Type";
    },
    renderEditCell: (params) => (
      <DataGridAutocomplete
        data={itemTypes}
        labelPath="name"
        value={itemTypes.find((type) => type.name === params.value) || null}
        id={params.publicId}
        field={params.field}
        row={params.row}
        handleChange={async (
          _event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue: string | RepackItemType | null
        ) => {
          await handleTypeChange(params, newValue, itemTypes, actions);
        }}
        handleAddToData={(_field: string, valueToAdd: string) =>
          actions.handleCreateItemType(valueToAdd)
        }
        width="150px"
      />
    ),
    
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const type = itemTypes.find((type: RepackItemType) => type.name === params.props.value);
      return { ...params.props, error: !type };
    },
  },
  {
    field: "attachedTicket",
    headerName: "Attached Ticket",
    width: 150,
    valueGetter: (params) => {
      const ticket = params.row.repackTicket;
      return ticket
        ? `${ticket.repackTicketType?.name || "Unknown"} ($${ticket.cost})`
        : "None";
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params) => <ItemActions item={params.row} actions={actions} />,
  },
];

