import { useState, useEffect, useCallback } from "react";
import useFetch from "./useFetch";
import useFetchPromise from "./useFetchPromise";

export type PaginationModel = {
  page: number,
  pageSize: number
}
export type SortModel = [{
  field?: string,
  sort?: string,
}] | null

export default function usePaginationFiltering(
    baseUrl: string, 
    totalRowUrl: string | null, 
    defaultPaginationModel: PaginationModel, 
    defaultFilteringModel: any, 
    defaultSortModel: SortModel,
    onDataRecieve?: (response: any) => void
  ) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [filteringModel, setFilteringModel] = useState(defaultFilteringModel);
  const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
  const [sortModel, setSortModel] = useState(defaultSortModel);

  const fetchPromise = useFetchPromise();
  const {data: totalRows, setData: setTotalRows} = useFetch(totalRowUrl, true, 0);

  const updateData = useCallback(async () => {
    if (!fetching) {
      setFetching(true)
      try {
        const responsePromise = await fetchPromise(
          baseUrl,
          "POST",
          {
            ...paginationModel,
            SortModel: sortModel ? sortModel : null,
            FilterModel: verifyFilterModel(),
          }
        );
    
        if (!responsePromise.ok) {
          throw new Error("Fetch request failed");
        }
        const responseData = await responsePromise.json();
        if (onDataRecieve !== undefined) {
          onDataRecieve(responseData)
        } else {
          setData(responseData.data);
          setTotalRows(responseData.totalRows)
        }
        if (loading) setLoading(false)
          setFetching(false)
          return responseData
      } catch(exception) {
        console.error("Failed to fetch data")
        setError(exception)
      }
      setFetching(false)
      return []

    }
  }, [filteringModel, paginationModel, sortModel, fetching]);
  const verifyFilterModel = ()=>{
    const items =  filteringModel.items.filter((filterItem: { operator: string; field: string; value: string | null | undefined; }) =>{
    if(filterItem.operator === "isEmpty" ||
    filterItem.operator === "isNotEmpty"  ||
    filterItem.operator === "isGraded" ||
    filterItem.operator === "isRaw"){
      return true;
    }

    if(filterItem.field === 'inventoryId' && filterItem.value === ""){
      return false;
    }
    return !(filterItem.value === undefined || filterItem.value === null)
  })
  return {...filteringModel, items: items}
  }

  useEffect(() => {
    //console.log(filteringModel)
    updateData()
  }, [totalRows, paginationModel, sortModel, filteringModel])

  return { data, setData, error, loading,
    fetching,
    totalRows,
    filteringModel, setFilteringModel,
    paginationModel, setPaginationModel,
    sortModel, setSortModel
  };
}

