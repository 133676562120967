import React from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { CacheSettings } from '../SettingsSubPages/CacheSettings';
import { LOCAL_STORAGE_KEYS } from '../CacheList';
import { ClipboardSettings } from '../SettingsSubPages/ClipboardSettings';

const DevSettings: React.FC = () => {
  return (
    <Paper 
      elevation={1} 
      sx={{ 
        p: 3,
        mt: 2,
        position: 'relative',
        pt: 4, // Extra padding top to account for the title positioning
        borderRadius: 2,
        border: '1px solid #ccc',
      }}
    >
      <Typography 
        variant="h6"
        sx={{
          position: 'absolute',
          top: -14,
          left: 16,
          backgroundColor: 'background.paper',
          px: 1
        }}
      >
        Developer Settings
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <CacheSettings storageKeys={LOCAL_STORAGE_KEYS} />
        <ClipboardSettings storageKeys={LOCAL_STORAGE_KEYS} />
      </Box>
    </Paper>
  );
};

export default DevSettings;