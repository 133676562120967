import { Box } from "@mui/system"
import DownloadBarcodes from "../DownloadBarcodes"
import { Paper, Typography } from "@mui/material"
import DearPriceSyncButtons from "../SettingsSubPages/DearPriceSyncButtons"

export const LogisticsSettings = () => {
  return (
    <div>
      <Paper 
            elevation={1} 
            sx={{ 
                p: 3,
                mt: 2,
                position: 'relative',
                pt: 4,
                borderRadius: 2,
                border: '1px solid #ccc'
            }}
        >
            <Typography 
                variant="h6"
                sx={{
                    position: 'absolute',
                    top: -14,
                    left: 16,
                    backgroundColor: 'background.paper',
                    px: 1
                }}
            >
                Logistics Settings
            </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <DownloadBarcodes/>

            <DearPriceSyncButtons/>        
          
        </Box>
        </Paper>
    </div>
)
}
