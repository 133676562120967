import { Box, Button } from "@mui/material";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";
interface CacheSettingsProps {
    storageKeys: readonly string[];
}

export const CacheSettings = ({ storageKeys }: CacheSettingsProps) => {
    const CACHE_KEY = LocalStorageKey.CACHE_SETTINGS;

    const cacheCurrentSettings = () => {
        const exportData: Record<string, any> = {};
        storageKeys.forEach(key => {
            const value = localStorage.getItem(key);
            if (value) {
                try {
                    exportData[key] = JSON.parse(value);
                } catch {
                    exportData[key] = value;
                }
            }
        });
        
        localStorage.setItem(CACHE_KEY, JSON.stringify(exportData));
    };

    const restoreFromCache = () => {
        const cachedSettings = localStorage.getItem(CACHE_KEY);
        if (!cachedSettings) {
            alert('No cached settings found');
            return;
        }

        try {
            const importData = JSON.parse(cachedSettings);
            Object.entries(importData).forEach(([key, value]) => {
                if (storageKeys.includes(key as any)) {
                    localStorage.setItem(key, JSON.stringify(value));
                }
            });
            window.location.reload();
        } catch (error) {
            console.error('Failed to restore cached settings:', error);
            alert('Failed to restore cached settings');
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
                variant="contained"
                onClick={cacheCurrentSettings}
            >
                Cache Current Settings
            </Button>

            <Button 
                variant="contained"
                onClick={restoreFromCache}
            >
                Restore from Cache
            </Button>
        </Box>
    );
}; 