import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material"
import { useState } from "react";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { SyncCardsToDear } from "../../../DearContent/DearAPICalls";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useFeatureFlags } from "../../../Contexts/FeatureFlagContexts/FeatureFlagContext";
import { Card } from "../Types/CardType";
interface AddToDearProps {
    selectedRows: any[];
}
interface LastSyncInfo {
    failedToCreateDearProducts: [],
    updatedFromExisting: [],
    createdNewDearProducts: [],
    cardsAlreadySynced: []
}
interface CardDearMapping {
    card: Card,
}
export const AddToDear = ({ selectedRows }: AddToDearProps) => {
    const { user } = useUser();
    const { newSnackbarMessage } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const { flagEnabled } = useFeatureFlags();

    const [lastSyncInfo, setLastSyncInfo] = useState<LastSyncInfo>({
        failedToCreateDearProducts: [],
        updatedFromExisting: [],
        createdNewDearProducts: [],
        cardsAlreadySynced: []
    });
    const handleClose = () => {
        setOpen(false);
    }

    // Verifies a cards record & creates a new card if card is not found within inventory system.
    async function verifyRecord() {
        if (!updating) {
            try {
                setUpdating(true)
                const cardIds = selectedRows.map((card) => card.id);
                const response = await SyncCardsToDear(cardIds, user);
                if (response.status == 200) {
                    const data = await response.json();
                    setLastSyncInfo(data);
                    setOpen(true)
                    
                    newSnackbarMessage("Successfully synced cards", 'success');
                } else {
                    newSnackbarMessage("Error synching cards, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error)
                newSnackbarMessage("Error synching cards, connection invalid please try again later", 'error');
            } finally {
                setUpdating(false)
            }
        }
    }
    const copyCardInventoryIds = (cards: Card[]) => {
        const inventoryIds = cards.map((card: Card) => card.inventoryId).join("\n");

        navigator.clipboard.writeText(inventoryIds)
        .then(() => {
            newSnackbarMessage("Copied to clipboard!", "success");
        })
        .catch((err) => {
            console.error(err)
            newSnackbarMessage("Failed to copy to clipboard.", "error");
        });
    }

    const copySuccessfulIds = () => {
        const inventoryIds1 = lastSyncInfo.updatedFromExisting.map((mapping: CardDearMapping) => mapping.card.inventoryId);
        const inventoryIds2 = lastSyncInfo.createdNewDearProducts.map((mapping: CardDearMapping) => mapping.card.inventoryId);
        const combined = inventoryIds1.concat(inventoryIds2);
        const combinedInventoryIds = combined.join("\n");
        navigator.clipboard.writeText(combinedInventoryIds)
        .then(() => {
            newSnackbarMessage("Copied to clipboard!", "success");
        })
        .catch((err) => {
            console.error(err)
            newSnackbarMessage("Failed to copy to clipboard.", "error");
        });
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Synching Cards into Dear Request Info</DialogTitle>
                <DialogContent>
                        <Alert sx={{marginBottom: "5px"}} severity="success">
                            {lastSyncInfo.updatedFromExisting.length + lastSyncInfo.createdNewDearProducts.length} cards synced to dear
                            <Tooltip title="Copy all successfully synced Cards ids" onClick={() => copySuccessfulIds()}>
                                <CopyAllIcon sx={{cursor: "pointer"}}/>
                            </Tooltip>
                        </Alert>
                        <Alert sx={{marginBottom: "5px"}} severity="info">
                            {lastSyncInfo.cardsAlreadySynced.length} cards already synced
                            <Tooltip title="Copy card ids already synced" onClick={() => copyCardInventoryIds(lastSyncInfo.cardsAlreadySynced)}>
                                <CopyAllIcon sx={{cursor: "pointer"}}/>
                            </Tooltip>
                        </Alert>
                        <Alert severity="error">
                            {lastSyncInfo.failedToCreateDearProducts.length} failed to sync cards into dear
                            <Tooltip title="Copy card ids that failed to sync" onClick={() => copyCardInventoryIds(lastSyncInfo.failedToCreateDearProducts)}>
                                <CopyAllIcon sx={{cursor: "pointer"}}/>
                            </Tooltip>
                        </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Syncs selected cards to dear">
                <span>
                    <Button color="primary" onClick={verifyRecord} disabled={!flagEnabled("dearsync") || (selectedRows.length <= 0 || updating)}>
                        Add to Dear
                    </Button>
                </span>
            </Tooltip>
        </>
    )
}
