import { Box } from "@mui/material";
import CardSyncCorrectionDataGrid from "../Dash/CardSyncCorrectionPage/CardSyncCorrectionDataGrid";
import { CardSyncCorrectionProvider } from "../Dash/CardSyncCorrectionPage/CardSyncCorrectionContext";

const CardSyncCorrectionPage = () => (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "5px",
      }}
    >
      <CardSyncCorrectionProvider>
        <CardSyncCorrectionDataGrid />
      </CardSyncCorrectionProvider>
    </Box>
  );
  
  export default CardSyncCorrectionPage;
  