import React from 'react';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ItemGrid from '../ItemGrid/ItemGrid';
import { useBuildRepacks } from '../../../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext';
import { BoxActions, ItemActionsType } from '../ItemGrid/types';

interface DetailPanelProps {
  itemActions: ItemActionsType;
  boxActions: Pick<BoxActions, 'handleDeleteBox'>;
}

const DetailPanel: React.FC<DetailPanelProps> = ({ itemActions, boxActions }) => {
  const { box, boxLoading, itemTypes, selectedBox } = useBuildRepacks();
  
  if (!box?.repackItems?.length) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="flex"
        flexDirection="column"
      >
        <Grid item padding={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50%"
          >
            {boxLoading && <CircularProgress />}
            {!boxLoading && (
              <Typography variant="h6" color="textSecondary">
                There are no items in this box yet
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Button 
            variant="text" 
            onClick={() => selectedBox && boxActions.handleDeleteBox(selectedBox)}
          >
            <DeleteIcon />
            Delete Box
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <ItemGrid
          items={box.repackItems}
          itemTypes={itemTypes}
          actions={itemActions}
        />
      </Grid>
      <Grid item>
        <Button 
          variant="text" 
          onClick={() => selectedBox && boxActions.handleDeleteBox(selectedBox)}
        >
          <DeleteIcon />
          Delete Box
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailPanel;