import {createContext, useContext, useState} from 'react';
import { PermissionFlagKey, PermissionRoles } from './PermissionFlagsEnum';

const UserContext = createContext(null);


let initialUser = null;
try {
    var data = localStorage.getItem("userData")
    if (data != null || data != undefined) {
        initialUser = JSON.parse(data)
    }
}   catch(e) {
    console.error("There was a problem getting the user data from local storage: ", e)
}

export function UserProvider(props) {
    const [user, setUser] = useState(initialUser)
    const [invalidationReason,setInvalidationReason] = useState("")

    function parseExperationToken(token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
      
        const { exp } = JSON.parse(jsonPayload);
        return exp * 1000
    }

    function getExpirationDate(){
        var token  = user["token"]
        const myToken = parseExperationToken(token)
        return myToken
    }

    function isTokenValid() {
        const exp = getExpirationDate()
        const isValid = Date.now() < exp 

        if(!isValid) {
            setInvalidationReason("Your login has expired. Please login again.")
        }

        return isValid
      }
    function isLoggedIn() {

        const userExists = user == null 
        if(userExists) {
            setInvalidationReason("You are not logged in. Please login.")
        }
        var loggedIn = userExists ? false : isTokenValid()
        
        return loggedIn
    }

    function hasPermission(role) {
        if (!isLoggedIn()) {
            return false
        }
        const token = user["token"]
        const claims = JSON.parse(atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
        const isDev = claims[PermissionFlagKey.DEV_FLAG] == "True"
        const isAdmin = claims[PermissionFlagKey.ADMIN_FLAG] == "True"
        const isRepack = claims[PermissionFlagKey.REPACK_FLAG] == "True"
        const isCommission = claims[PermissionFlagKey.COMMISSION_FLAG] == "True"
        const isMarketing = claims[PermissionFlagKey.MARKETING_FLAG] == "True"
        const isCards = claims[PermissionFlagKey.CARDS_FLAG] == "True"
        const isLogistics = claims[PermissionFlagKey.LOGISTICS_FLAG] == "True"

        if(role == PermissionRoles.DEV){
            return isDev
        }
        if(role == PermissionRoles.ADMIN){
            return isAdmin || isDev
        }
        if(role == PermissionRoles.REPACK){
            return isRepack || isAdmin || isDev
        }
        if(role == PermissionRoles.COMMISSION){
            return isCommission || isAdmin || isDev
        }
        if(role == PermissionRoles.MARKETING){
            return isMarketing || isAdmin || isDev
        }
        if(role == PermissionRoles.CARDS){
            return isCards || isAdmin || isDev
        }
        if(role == PermissionRoles.LOGISTICS){
            return isLogistics || isAdmin || isDev
        }
        return false;

    }

    const contextValues = {
        user,
        setUser,
        isLoggedIn,
        invalidationReason,
        setInvalidationReason,
        getExpirationDate,
        hasPermission
    }
    return( 
        <UserContext.Provider value={contextValues}>
            {props.children}
        </UserContext.Provider>
    )
}

export function useUser() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("Invalid user")
    }
    return context;
}

