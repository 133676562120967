import React from "react";
import InfoGrid from "./InfoGrid";

import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RepackSeriesGrid from "./RepackSeriesGrid";

const ViewRepacks: React.FC = () => {

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">View Repacks</Typography>
        {/* TODO: change this to be a modal to select staged repacks or create a new one */}
        <Button
          variant="contained"
          component={Link}
          to="/dashboard/repack/create"
        >
          Create a Repack
        </Button>
      </div>
      <InfoGrid />
      <RepackSeriesGrid />
    </div>
  );
};

export default ViewRepacks;
