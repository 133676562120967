import React from 'react';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { createColumns } from './columns';
import { ItemGridProps } from './types';
import { RepackItem } from '../../../../Types/RepackItem';

const ItemGrid: React.FC<ItemGridProps> = ({
  items,
  itemTypes,
  actions,
  isLoading = false,
}) => {
  const sortedItems = React.useMemo(() => 
    [...items].sort((a, b) => (a.slotNumber || 0) - (b.slotNumber || 0)),
    [items]
  );

  const columns = React.useMemo(() => 
    createColumns(itemTypes, actions),
    [itemTypes, actions]
  );

  const handleProcessRowUpdate = async (newRow: GridRowModel) => {
    const result = await actions.handleUpdateItem(newRow as RepackItem);
    if (!result) {
      throw new Error('Failed to update item');
    }
    return result;
  };

  return (
    <DataGridPro
      getRowId={(row) => row.id}
      rows={sortedItems}
      columns={columns}
      loading={isLoading}
      processRowUpdate={handleProcessRowUpdate}
      onProcessRowUpdateError={(error) => {
        console.error("Error processing item update:", error);
      }}
      disableRowSelectionOnClick
      autoHeight
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
        sorting: { sortModel: [{ field: 'slotNumber', sort: 'asc' }] },
      }}
    />
  );
};

export default ItemGrid;