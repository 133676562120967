import { PermissionRoles } from "../Components/CommonUtility/PermissionFlagsEnum"
import { useUser } from "../Components/CommonUtility/UserContext"
import DevSettings from "../Dash/Settings/SettingsRolePages/DevSettings"

import {Box} from '@mui/material'
import { LogisticsSettings } from "../Dash/Settings/SettingsRolePages/LogisticsSettings"
import { LocalSettings } from "../Dash/Settings/SettingsRolePages/LocalSettings"

const SettingsPage = () => {
    const {hasPermission} = useUser()
    return(
    <Box sx={{
                width: '90%',
                paddingLeft: '10%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}>
                <LocalSettings/>
                {hasPermission(PermissionRoles.LOGISTICS) && <LogisticsSettings/>}
                {hasPermission(PermissionRoles.DEV) && <DevSettings/>}
    </Box>)

}

export default SettingsPage;
