import React, { useState } from 'react';
import FetchModule from "../../../Modules/FetchModule";
import { Box, Button } from '@mui/material';
import { Spinner } from 'reactstrap';

const fetchData = new FetchModule();

interface ButtonState {
  disabled: boolean;
  active: string;
}

const DearPriceSyncButtons: React.FC = () => {
  const [buttonState, setButtonState] = useState<ButtonState>({
    disabled: false,
    active: "none"
  });

  const updateState = (name: keyof ButtonState, value: string | boolean) => {
    setButtonState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const refreshDB = async () => {
    updateState('disabled', true);
    updateState('active', '');
    await fetchData.promise('/Refresh/dear', true, 'POST2');
    updateState('disabled', false);
    updateState('active', 'none');
  };

  const read = async () => {
    updateState('disabled', true);
    updateState('active', '');
    await fetchData.promise("/PriSync/update/prices", false, 'POST2');
    updateState('disabled', false);
    updateState('active', 'none');
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button 
        disabled={buttonState.disabled} 
        onClick={refreshDB}
        variant="contained"
        color="primary"
      >
        Refresh Dear Items
      </Button>

      <Button 
        disabled={true}
        onClick={read}
        variant="contained"
        color="primary"

      >
        Refresh PriSync Prices
      </Button>

      <Spinner 
        color="light" 
        style={{display: buttonState.active}}
      />
    </Box>
  );
};

export default DearPriceSyncButtons;