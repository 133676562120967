import React from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { RepackBoxType } from '../../../../Types/RepackBoxType';

interface BoxTypeSelectProps {
  boxTypes: RepackBoxType[];
  selectedBoxType: number;
  onBoxTypeChange: (boxTypeId: number) => void;
}

const BoxTypeSelect: React.FC<BoxTypeSelectProps> = ({ 
  boxTypes, 
  selectedBoxType,
  onBoxTypeChange 
}) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    onBoxTypeChange(Number(event.target.value));
  };

  return (
    <Select
      fullWidth
      id="select-repack-box-type"
      value={selectedBoxType}
      onChange={handleChange}
      displayEmpty
    >
      {!boxTypes?.length ? (
        <MenuItem value="" disabled>
          No box types available
        </MenuItem>
      ) : (
        boxTypes.map((type: RepackBoxType) => (
          <MenuItem key={type.publicId} value={type.id}>
            {type.name || "Unknown Type"}
          </MenuItem>
        ))
      )}
    </Select>
  );
};

export default BoxTypeSelect;