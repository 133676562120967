import { ICardSkuDearCollision } from '../Dash/CardSyncCorrectionPage/ICardSkuDearCollision';
import FetchModule from '../Modules/FetchModule';
const fetchModule = new FetchModule();


export async function SyncCardsToDear(
    cards: number[],
    user: any,
){
    const response = await fetchModule.postResponseBody("/card/dearsync", "POST", 
    {
        UserId: user["id"],
        Cards: cards,

    })
    return response    
}

export async function DearSkuCollissionUpdate(
    collision: ICardSkuDearCollision,
    user: any,
) {
    const response = await fetchModule.postResponseBody("/card/dearSkuCollision/update", "PUT", 
        {
            UserId: user["id"],
            CardSkuDearCollision: collision,
    
        })
        return response    
}