import { useState } from "react";
import { AlertColor } from "@mui/material";
import { RepackBox } from "../../../Types/RepackBox";
import { RepackBoxType } from "../../../Types/RepackBoxType";
import {
  CreateNewBox,
  DeleteRepackBox,
  UpdateRepackBox,
} from "../../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksAPICalls";
import { useBuildRepacks } from "../../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackTicket } from "../../../Types/RepackTicket";
import { useRepackCreatorCard } from "../../../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { Card } from "../../../../SinglesPage/Types/CardType";
import {
  DeletedItem,
  DeleteResponse,
} from "../../../Types/RepackItem";
import { RepackCase } from "../../../Types/RepackCaseType";

export const useBoxActions = (
  user: any,
  boxes: RepackBox[] | undefined,
  setBoxes: (boxes: RepackBox[] | undefined) => void,
  newSnackbarMessage: (message: string, severity: AlertColor) => void
) => {
  const { boxTypes, setAllTickets } = useBuildRepacks();
  const { setRows } = useRepackCreatorCard();
  const [isAddingToCase, setIsAddingToCase] = useState(false);

  const handleCreateBox = async (selectedBoxType: number, seriesId: string) => {
    try {
      const type = boxTypes.find(
        (type: RepackBoxType) => type.id === selectedBoxType
      );
      if (!type) {
        newSnackbarMessage("Invalid repack type selected.", "error");
        return;
      }
      const response = await CreateNewBox(user, type, seriesId);

      if (response.ok) {
        newSnackbarMessage("Box created successfully!", "success");
        const newBox = await response.json();
        const newBoxes = [...(boxes || []), newBox.result];
        setBoxes(newBoxes);
      } else {
        newSnackbarMessage("Failed to create box.", "error");
      }
    } catch (error) {
      console.error("Failed to create box:", error);
      newSnackbarMessage("Error creating box.", "error");
    }
  };

  const handleUpdateBox = async (updatedRow: RepackBox) => {
    try {
      const b = updatedRow ?? [];
      const items = b.items ?? [];
      const response = await UpdateRepackBox(updatedRow, items, user);

      if (response.ok) {
        newSnackbarMessage("Repack box updated successfully!", "success");
        const updatedBoxes = boxes?.map((rb: RepackBox) =>
          rb.id === updatedRow.id ? { ...rb, ...updatedRow } : rb
        );
        return updatedBoxes;
      } else {
        newSnackbarMessage("Failed to update repack box.", "error");
      }
    } catch (error) {
      console.error("Failed to update repack box:", error);
      newSnackbarMessage("Error updating repack box.", "error");
    }
  };

  const handleDeleteBox = async (selectedBox: RepackBox) => {
    if (!selectedBox) {
      newSnackbarMessage("No box selected.", "error");
      return false;
    }

    try {
      const response = await DeleteRepackBox(selectedBox, user);

      if (response.ok) {
        const removedItemsJson: DeleteResponse = await response.json();
        const deletedItems: DeletedItem[] = removedItemsJson.deletedItems || [];

        const removedTickets: RepackTicket[] = deletedItems
          .filter((item: DeletedItem) => item.ticket)
          .map((item: DeletedItem) => item.ticket);

        if (removedTickets.length > 0) {
          setAllTickets((prev: RepackTicket[]) => {
            return prev.map((ticket: RepackTicket) => {
              const matchingTicket = removedTickets.find(
                (removed: RepackTicket) => removed.publicId === ticket.publicId
              );
              if (matchingTicket) {
                return {
                  ...ticket,
                  repackItemId: undefined,
                  repackItem: undefined,
                };
              }
              return ticket;
            });
          });
        }

        const removedCards: Card[] = deletedItems
          .filter((item: DeletedItem) => item.card)
          .map((item: DeletedItem) => item.card);

        if (removedCards.length > 0) {
          setRows((prev: Card[]) => [...prev, ...removedCards]);
        }

        const updatedBoxes = (boxes || []).filter(
          (b: RepackBox) => b.id !== selectedBox.id
        );
        setBoxes(updatedBoxes);

        newSnackbarMessage("Box deleted successfully!", "success");
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error deleting box:", error);
      newSnackbarMessage("Failed to delete box.", "error");
      return false;
    }
  };

  const handleAddToCase = async (selectedBox: RepackBox, selectedCase: RepackCase | null): Promise<boolean> => {
    if (isAddingToCase) return false;
    if (!selectedCase) {
      newSnackbarMessage("No case selected.", "error");
      return false;
    }
  
    try {
      setIsAddingToCase(true);
      const items = selectedBox.repackItems ?? [];
      const response = await UpdateRepackBox(
        selectedBox,
        items,
        user,
        selectedCase.publicId
      );
  
      if (response.ok) {
        newSnackbarMessage("Box added to case successfully!", "success");
        const updatedBoxes = (boxes || []).filter(
          (b: RepackBox) => b.id !== selectedBox.id
        );
        setBoxes(updatedBoxes);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Failed to add box to case:", error);
      newSnackbarMessage("Error adding box to case.", "error");
      return false;
    } finally {
      setIsAddingToCase(false);
    }
  };

  return {
    isAddingToCase,
    handleCreateBox,
    handleUpdateBox,
    handleDeleteBox,
    handleAddToCase,
  };
};
