import { AlertColor } from "@mui/material";
import { RepackItem } from "../../../Types/RepackItem";
import {
  CreateNewItemType,
  DeleteItem,
  RemoveTicketFromCard,
  UpdateItem,
} from "../../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksAPICalls";
import { useRepackCreatorCard } from "../../../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { Card } from "../../../../SinglesPage/Types/CardType";
import { CreateItemTypeResponse, DeleteItemResponse, ItemActionsType, RemoveTicketResponse, SetBoxesFunction, SetBoxFunction, SetTicketsFunction, UpdateItemResponse } from "./types";
import { RepackBox } from "../../../Types/RepackBox";
import { RepackTicket } from "../../../Types/RepackTicket";
import { RepackItemType } from "../../../Types/RepackItemType";


export const useItemActions = (
  user: any,
  setBox: SetBoxFunction,
  setBoxes: SetBoxesFunction,
  setAllTickets: SetTicketsFunction,
  newSnackbarMessage: (message: string, severity: AlertColor) => void,
  onOpenTicketDialog: (item: RepackItem) => void
): ItemActionsType => {
  const { setRows } = useRepackCreatorCard();

  const handleUpdateItem = async (updatedRow: RepackItem): Promise<RepackItem | null> => {
    try {
      if (!updatedRow || !updatedRow.id) {
        newSnackbarMessage("Invalid item data for update.", "error");
        return null;
      }

      const response = await UpdateItem(user, updatedRow);

      if (response.ok) {
        const rData: UpdateItemResponse = await response.json();
        const rDataResult = rData.result;

        setBox((prevBox: RepackBox | null) => {
          if (!prevBox?.repackItems) return prevBox;
          
          const updatedItems = [...prevBox.repackItems]
            .map((item: RepackItem) => {
              if (item.id === updatedRow.id) {
                return {
                  ...item,
                  ...rDataResult,
                  repackItemType: updatedRow.repackItemType,
                  repackItemTypeId: updatedRow.repackItemTypeId,
                  slotNumber: updatedRow.slotNumber,
                };
              }
              return item;
            })
            .sort((a: RepackItem, b: RepackItem) => (a.slotNumber || 0) - (b.slotNumber || 0));

          return {
            ...prevBox,
            repackItems: updatedItems,
          };
        });

        setBoxes((prevBoxes: RepackBox[]) => {
          if (!prevBoxes) return prevBoxes;
          
          return prevBoxes.map((box: RepackBox) => {
            if (!box.repackItems) return box;
            
            const shouldUpdate = box.repackItems.some(
              (item: RepackItem) => item.id === updatedRow.id
            );
            
            if (!shouldUpdate) return box;

            return {
              ...box,
              repackItems: box.repackItems.map((item: RepackItem) => {
                if (item.id === updatedRow.id) {
                  return {
                    ...item,
                    ...rDataResult,
                    repackItemType: updatedRow.repackItemType,
                    repackItemTypeId: updatedRow.repackItemTypeId,
                    slotNumber: updatedRow.slotNumber,
                  };
                }
                return item;
              }).sort((a: RepackItem, b: RepackItem) => (a.slotNumber || 0) - (b.slotNumber || 0)),
            };
          });
        });

        newSnackbarMessage("Repack Item updated successfully!", "success");
        return rDataResult;
      }

      newSnackbarMessage("Failed to update repack item.", "error");
      return updatedRow;
    } catch (error) {
      console.error('Error in handleUpdateItem:', error);
      newSnackbarMessage("Error updating repack item.", "error");
      return updatedRow;
    }
  };

  const handleDeleteItem = async (item: RepackItem): Promise<boolean> => {
    try {
      const response = await DeleteItem(item, user);
      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      const updatedBoxData: DeleteItemResponse = await response.json();
      const updatedBox = updatedBoxData.result;

      if (item.repackTicket) {
        setAllTickets((prevTickets: RepackTicket[]) =>
          prevTickets.map((ticket) =>
            ticket.publicId === item?.repackTicket?.publicId
              ? { ...ticket, repackItemId: undefined, repackItem: undefined }
              : ticket
          )
        );
      }

      if (item.repackCard) {
        setRows((prev: Card[]) => [...prev, item.repackCard]);
      }

      setBox((prevBox: RepackBox | null) => {
        if (!prevBox) return prevBox;
        return {
          ...prevBox,
          repackItems: updatedBox.repackItems
        };
      });

      setBoxes((prevBoxes: RepackBox[]) => 
        prevBoxes.map((b: RepackBox) => 
          b.id === updatedBox.id 
            ? { ...b, repackItems: updatedBox.repackItems }
            : b
        )
      );

      newSnackbarMessage("Item deleted successfully!", "success");
      return true;
    } catch (error) {
      console.error("Error deleting item:", error);
      newSnackbarMessage("Failed to delete item.", "error");
      return false;
    }
  };

  const handleCreateItemType = async (newTypeName: string): Promise<RepackItemType | null> => {
    try {
      const response = await CreateNewItemType(newTypeName, user);
      if (response.ok) {
        const newType: CreateItemTypeResponse = await response.json();
        if (newType?.result) {
          return newType.result;
        }
      }
      newSnackbarMessage("Failed to create new item type.", "error");
      return null;
    } catch (error) {
      console.error("Failed to create new item type:", error);
      newSnackbarMessage("Error creating new item type.", "error");
      return null;
    }
  };

  const handleAddTicketToCard = (item: RepackItem): void => {
    onOpenTicketDialog(item);
  };

  const handleRemoveTicketFromCard = async (item: RepackItem): Promise<void> => {
    try {
      const response = await RemoveTicketFromCard(item.publicId, user);
      if (!response.ok) throw new Error("Failed to remove ticket from card");

      const updatedData: RemoveTicketResponse = await response.json();
      if (!updatedData?.result) throw new Error("Invalid response data");

      setBox((prevBox: RepackBox | null) => {
        if (!prevBox?.repackItems) return prevBox;
        return {
          ...prevBox,
          repackItems: prevBox.repackItems.map((repackItem: RepackItem) =>
            repackItem.publicId === item.publicId
              ? updatedData.result
              : repackItem
          ),
        };
      });

      setBoxes((prevBoxes: RepackBox[]) => {
        if (!prevBoxes) return prevBoxes;
        return prevBoxes.map((box: RepackBox) => ({
          ...box,
          repackItems: box.repackItems?.map((repackItem: RepackItem) =>
            repackItem.publicId === item.publicId
              ? updatedData.result
              : repackItem
          ),
        }));
      });

      if (item.repackTicket) {
        setAllTickets((prevTickets: RepackTicket[]) =>
          prevTickets.map((ticket: RepackTicket) =>
            ticket.publicId === item.repackTicket?.publicId
              ? { ...ticket, repackItemId: undefined, repackItem: undefined }
              : ticket
          )
        );
      }

      newSnackbarMessage("Ticket removed successfully", "success");
    } catch (error) {
      console.error("Error removing ticket:", error);
      newSnackbarMessage("Failed to remove ticket", "error");
    }
  };

  return {
    handleUpdateItem,
    handleDeleteItem,
    handleCreateItemType,
    handleAddTicketToCard,
    handleRemoveTicketFromCard,
  };
};