import React from "react";
import Box from "@mui/material/Box";
import { GridColDef } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { RepackTicketPrize } from "../Types/RepackTicket";

interface BoxDetailPanelProps {
    row: any;
    itemColumns: GridColDef[];
  }
  
  export const BoxDetailPanel: React.FC<BoxDetailPanelProps> = ({ row }) => {
    const { box, boxLoading, selectedBox, itemTypes } = useBuildRepacks();
  
    const mapRepackItemTypeName = (item: any) => {
      const type = itemTypes.find(type => type.id === item.repackItemTypeId);
      return type ? type.name : "Unknown Type";
    };
  
    const itemColumns: GridColDef[] = [
      { field: "id", headerName: "ID", flex: 0.5 },
      { 
        field: "name",
        headerName: "Name",
        flex: 1,
        valueGetter: (params) => {
          const item = params.row;
          const cardName = item.repackCard?.name || "";
          const ticketName = item.repackTicket?.repackTicketType?.name || "";
  
          if (item.repackCard && item.repackTicket) {
            return `${cardName} (with ${ticketName})`;
          }
  
          return cardName || ticketName || "Unknown Item";
        }
      },
      { 
        field: "type", 
        headerName: "Type", 
        flex: 1,
        valueGetter: (params) => mapRepackItemTypeName(params.row)
      },
      {
        field: "comp",
        headerName: "Comp",
        flex: 0.5,
        valueGetter: (params) => {
          const item = params.row;
          const cardComp = Number(item.repackCard?.comp || 0);
          const ticketCost = Number(item.repackTicket?.cost || 0);
          return cardComp + ticketCost;
        }
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        flex: 0.5,
        valueGetter: (params) => {
          const item = params.row;
          const cardCost = Number(item.repackCard?.totalCost || 0);
          const ticketCost = Number(item.repackTicket?.cost || 0);
          return cardCost + ticketCost;
        }
      },
      {
        field: "difference",
        headerName: "Difference",
        flex: 0.5,
        valueGetter: (params) => {
          const item = params.row;
          if (item.repackCard) {
            const comp = Number(item.repackCard.comp || 0);
            const cost = Number(item.repackCard.totalCost || 0);
            return comp - cost;
          }
          return 0;
        }
      },
      {
        field: "attachedTicket",
        headerName: "Attached Ticket",
        flex: 1,
        valueGetter: (params) => {
          const ticket = params.row.repackTicket;
          if (!ticket) return "None";
          const ticketName = ticket.repackTicketType?.name || "Unknown";
          const ticketCost = ticket.cost;
          const potentialPrizes = ticket.repackTicketPrizes || [];
          
          let prizeInfo = '';
          if (potentialPrizes.length > 0) {
            const prizeValues = potentialPrizes
              .map((p: RepackTicketPrize) => `${p.name} ($${p.cost})`)
              .join(', ');
            prizeInfo = ` - Potential Prizes: ${prizeValues}`;
          }
          
          return `${ticketName} ($${ticketCost})${prizeInfo}`;
        }
      }
    ];
  
    if (boxLoading && row.id === selectedBox?.id) {
      return (
        <Grid container justifyContent="center" alignItems="flex" flexDirection="column">
          <Grid item padding={2}>
            <Box display="flex" justifyContent="center" alignItems="center" height="50%">
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
  
    const currentBox = (selectedBox?.id === row.id && box) ? box : row;
    const items = currentBox?.repackItems || [];
  
    if (!items.length) {
      return (
        <Grid container justifyContent="center" alignItems="flex" flexDirection="column">
          <Grid item padding={2}>
            <Box display="flex" justifyContent="center" alignItems="center" height="50%">
              <Typography variant="h6" color="textSecondary">
                There are no items in this box yet
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    }
  
    return (
      <Box sx={{ p: 2, height: "100%" }}>
        <Typography variant="h6" gutterBottom>
          Items in Box {currentBox.id}
        </Typography>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={items}
            columns={itemColumns}
            density="compact"
            hideFooter
            disableRowSelectionOnClick
            disableColumnMenu
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f5f5f5",
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff",
              },
            }}
          />
        </div>
      </Box>
    );
  };