import { Box, Divider, Typography } from '@mui/material';
import { ShowsProvider } from '../Contexts/ShowContexts/Shows/ShowsContext';
import { SingleShowProvider } from '../Contexts/ShowContexts/SingleShow/SingleShowContext';
import SingleShowPage from './SingleShowPage';
import ShowsDataGrid from '../Components/ShowComponents/ShowsDataGrid';
import BulkShowUploader from '../Components/ShowComponents/BulkShowUploader';
import { FileUploaderProvider } from '../Components/FileUploader/FileUploaderContext';
import { ShowProvider } from '../Contexts/ShowContexts/Shows/ShowContext';
type ShowPageProps = {
    pageType?: string
}

const ShowPage = ({ pageType} : ShowPageProps)  => {

    if (pageType == "single") {
        const path = window.location.pathname.split("/")[3]
        return(
             <SingleShowProvider showPublicId={path} >
                <SingleShowPage />
             </SingleShowProvider>
        )
    }
    return(
    <Box
        sx={{
            width: '%',
            height: '100%',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column'
        }}
        >
        <Box sx={{
            width: '100%',
            height: '40px',
            display: 'flex',
            flexDirection: 'row',
        }}>
            <Typography variant="h4" sx={{marginBottom: '3px'}}>Show Manager Page</Typography>
            <FileUploaderProvider endpoint={`/show/bulkshowupload/`} accepts='text/csv' maxFiles={100}>
                <ShowProvider>            
                    <BulkShowUploader />
                </ShowProvider>
            </FileUploaderProvider>
        </Box>
        <Divider sx={{borderBottomWidth: 3}}/>
        <ShowsProvider>
            <ShowsDataGrid/>
        </ShowsProvider>
    </Box>)
}
  
  export default ShowPage;
  