import ShowsDatagridToolbar from './ShowsDataGridToolbar';
import React, { useEffect } from 'react';
import { useShows } from '../../Contexts/ShowContexts/Shows/ShowsContext';
import { ShowProvider } from '../../Contexts/ShowContexts/Shows/ShowContext';
import dayjs from 'dayjs';
import SingleShowViewButton from '../SingleShowComponents/SingleShowViewButton';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import utc from 'dayjs/plugin/utc';
import IsNullFilter from '../../Dash/SinglesPage/FilterComponents/IsNullFilter';
import EditShowGridButton from './EditShowGridButton';
type ShowsDataGridProps = {
    children?: React.ReactNode;
}
/* 
    ShowsDatagrid component must be wrapped by a ShowHostsProvider to work properly
*/
dayjs.extend(utc)

const ShowsDataGrid = ({} : ShowsDataGridProps)  => {
    const {
        shows, showsError, showsLoading, addShow, updateShow,
        showPaginationModel, setShowPaginationModel, totalShowRows,
        setFilteringModel, filteringModel, 
        setSortModel, fetching, gridApiRef
    } = useShows();

    useEffect(() => {

    }, [shows])
    const label = {
        "isEmpty": "Not Uploaded",
        "isNotEmpty": "Uploaded"
    }
    const { Operators: IsUploadedFilter } = IsNullFilter( {
        label: label,
    } );

    const columns: GridColDef[] = [
        //{ field: 'publicId', headerName: 'Public Id', width: 200 },
        { field: 'title', headerName: 'Title', width: 300, type: 'string'},
        { field: 'displayTitle', headerName: 'Display Title', width: 300, type: 'string'},

        { field: 'channel', headerName: 'Channel', width: 250,
            valueGetter: (params) => {
                const { row } = params;
                return row.showChannel.channelName
            },
        },
        { field: 'platform', headerName: 'Platform', width: 250,
            valueGetter: (params) => {
                const { row } = params;
                return row.showChannel.platform.platformName
            },
        },
        { field: 'startTime', headerName: 'Start time of Show', width: 200, type: 'dateTime',
            valueGetter: (params) => {
                return new Date(params.value)
            }
        },
        {field: 'fileUploadedId', headerName: 'Uploaded file', width: 200, type: 'boolean',
            filterOperators: IsUploadedFilter,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            filterable: false,
            sortable: false,
            getActions: ({ id, row }) => {
                return [
                    <EditShowGridButton key={id + "edit"} id={id} row={row} />,
                    <SingleShowViewButton key={id + "show"} show={row} id={''} />
                ];
              },
        }
    ];
    if (showsLoading) return <div>Loading...</div>;
    if (showsError) return <div>Error Loading</div>

    return(
        <>
        <ShowProvider addShow={addShow} updateShow={updateShow}>
           <DataGridPro 
                apiRef={gridApiRef}
                sx={{height: '500px'}}
                rows={shows}
                columns={columns}
                slots={{
                    toolbar: ShowsDatagridToolbar
                }}
                initialState={{
                    pagination: {paginationModel: showPaginationModel},
                    filter: {
                        filterModel: filteringModel
                    },
                }}
                paginationMode="server"
                pagination
                sortingMode="server"
                pageSizeOptions={[25, 100, 500]}
                rowCount={totalShowRows}
                onPaginationModelChange={(newPaginationModel) => {
                    setShowPaginationModel(newPaginationModel)
                }}
                loading={fetching}
                filterMode="server"
                onFilterModelChange={(newFilterModel) => {
                    setFilteringModel(newFilterModel)
                }}
                onSortModelChange={(newSortModel) => {
                    setSortModel(newSortModel)
                }}
           />
           </ShowProvider>
        </>
    )
}
  
  
  export default ShowsDataGrid;
  