import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";

interface ClipboardSettingsProps {
    storageKeys: readonly string[];
}

export const ClipboardSettings = ({ storageKeys }: ClipboardSettingsProps) => {
    const [importText, setImportText] = useState('');

    const exportLocalStorage = () => {
        const exportData: Record<string, any> = {};
        storageKeys.forEach(key => {
            const value = localStorage.getItem(key);
            if (value) {
                try {
                    exportData[key] = JSON.parse(value);
                } catch {
                    exportData[key] = value;
                }
            }
        });
        
        const exportString = JSON.stringify(exportData, null, 2);
        navigator.clipboard.writeText(exportString);
    };

    const importLocalStorage = () => {
        try {
            const importData = JSON.parse(importText);
            Object.entries(importData).forEach(([key, value]) => {
                if (storageKeys.includes(key as any)) {
                    localStorage.setItem(key, JSON.stringify(value));
                }
            });
            window.location.reload();
        } catch (error) {
            console.error('Failed to import settings:', error);
            alert('Invalid import data format');
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button 
                variant="contained"
                onClick={exportLocalStorage}
            >
                Copy Local Storage to Clipboard
            </Button>

            <TextField
                multiline
                rows={4}
                value={importText}
                onChange={(e) => setImportText(e.target.value)}
                placeholder="Paste exported settings here..."
            />

            <Button 
                variant="contained"
                onClick={importLocalStorage}
                disabled={!importText}
            >
                Import Settings from Clipboard
            </Button>
        </Box>
    );
}; 