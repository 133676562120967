import { Box, Button, Paper, Typography } from "@mui/material";

export const LocalSettings = () => {
    return (
        <Paper 
            elevation={1} 
            sx={{ 
                p: 3,
                mt: 2,
                position: 'relative',
                pt: 4,
                borderRadius: 2,
                border: '1px solid #ccc'
            }}
        >
            <Typography 
                variant="h6"
                sx={{
                    position: 'absolute',
                    top: -14,
                    left: 16,
                    backgroundColor: 'background.paper',
                    px: 1
                }}
            >
                Local Settings
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Button 
                    variant="contained" 
                    onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                    }}
                    color="primary"
                >
                    Clear Local Storage
                </Button>
            </Box>
        </Paper>
    );
};