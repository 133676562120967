import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { GridRowId } from "@mui/x-data-grid-pro";
import CollapsiblePaper from "../../../../Components/Common/CollapsiblePaper";
import { useSnackbar } from "../../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../../Components/CommonUtility/UserContext";
import { useBuildRepacks } from "../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackBox } from "../../Types/RepackBox";
import { RepackItem } from "../../Types/RepackItem";
import { useBoxActions } from "./Hooks/useBoxActions";
import { useItemActions } from "./Hooks/useItemActions";
import BoxGrid from "./Components/BoxGrid/BoxGrid";
import TicketSelectionDialog from "../TicketSelectionDialog";
import { AddTicketToCard } from "../../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksAPICalls";

const StagedBoxesPreview = () => {
  const [searchParams] = useSearchParams();
  const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
  const [selectedRepackItem, setSelectedRepackItem] =
    useState<RepackItem | null>(null);
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [, setBoxRows] = useState<RepackBox[]>([]);

  const seriesId = searchParams.get("id") || "";
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();

  const {
    setSelectedBox,
    selectedBox,
    boxes,
    setBoxes,
    setSelectedSeriesId,
    setBox,
    setAllTickets,
    availableTickets,
  } = useBuildRepacks();

  const handleTicketDialogOpen = (item: RepackItem) => {
    setSelectedRepackItem(item);
    setTicketDialogOpen(true);
  };

  const boxActions = useBoxActions(user, boxes, setBoxes, newSnackbarMessage);
  const itemActions = useItemActions(user, setBox, (value) => setBoxes(value as RepackBox[]), setAllTickets, newSnackbarMessage, handleTicketDialogOpen);

  useEffect(() => {
    const newBoxRows = (boxes || []).map((b: RepackBox) => ({
      ...b,
    }));
    setBoxRows(newBoxRows);
  }, [boxes]);

  useEffect(() => {
    setSelectedSeriesId(seriesId);
  }, [expandedRowIds, setSelectedSeriesId, seriesId]);

  const handleBoxSelection = (boxId: GridRowId | null) => {
    if (!boxId) {
      setExpandedRowIds([]);
      setSelectedBox(null);
      setBox(null);
      return;
    }

    if (boxId === selectedBox?.id) {
      handleBoxSelection(null);
      return;
    }

    const foundBox = boxes?.find((rb: RepackBox) => rb.id === boxId);
    if (!foundBox) {
      console.error("Box not found");
      newSnackbarMessage("Box not found.", "error");
      return;
    }

    setExpandedRowIds([boxId]);
    setSelectedBox(foundBox);
    setBox(foundBox);
  };

  const handleRowExpandChange = (ids: GridRowId[]) => {
    if (ids.length === 0) {
      handleBoxSelection(null);
    } else if (ids.length === 1) {
      handleBoxSelection(ids[0]);
    } else if (ids.length === 2) {
      newSnackbarMessage(
        "Please close the other box before opening this one.",
        "warning"
      );
      handleBoxSelection(ids[1]);
    }
  };

  const handleTicketSelect = async (ticketId: string) => {
    if (!selectedRepackItem) return;

    try {
      const response = await AddTicketToCard(
        selectedRepackItem.publicId,
        ticketId,
        user
      );

      if (!response.ok) throw new Error("Failed to add ticket to card");

      const updatedItem = await response.json();
      if (!updatedItem?.result) throw new Error("Invalid response data");

      setBox((prevBox) => {
        if (!prevBox?.repackItems) return prevBox;
        return {
          ...prevBox,
          repackItems: prevBox.repackItems.map((item) =>
            item.publicId === selectedRepackItem.publicId ? updatedItem.result : item
          ),
        };
      });

      setBoxes((prevBoxes) => {
        if (!prevBoxes) return prevBoxes;
        return prevBoxes.map((box) => ({
          ...box,
          repackItems: box.repackItems?.map((item) =>
            item.publicId === selectedRepackItem.publicId ? updatedItem.result : item
          ),
        }));
      });

      setAllTickets((prevTickets) => {
        return prevTickets.map((ticket) =>
          ticket.publicId === ticketId
            ? { ...ticket, repackItemId: updatedItem.result.id }
            : ticket
        );
      });

      newSnackbarMessage("Ticket successfully added to card", "success");
    } catch (error) {
      console.error("Error adding ticket to card:", error);
      newSnackbarMessage("Failed to add ticket to card", "error");
    } finally {
      setTicketDialogOpen(false);
      setSelectedRepackItem(null);
    }
  };

  return (
    <CollapsiblePaper
      title="Staged Boxes Preview"
      closeTitle="Close Preview"
      openTitle="Open Preview"
    >
      <BoxGrid
        expandedRowIds={expandedRowIds}
        onRowExpandChange={handleRowExpandChange}
        seriesId={seriesId}
        onBoxSelection={handleBoxSelection}
        boxActions={boxActions}
        itemActions={itemActions}
      />
      <TicketSelectionDialog
        open={ticketDialogOpen}
        onClose={() => setTicketDialogOpen(false)}
        onSelect={handleTicketSelect}
        availableTickets={availableTickets}
      />
    </CollapsiblePaper>
  );
};

export default StagedBoxesPreview;
