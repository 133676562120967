export enum LocalStorageKey {
  CARD_DASHBOARD_WIDTH = "cardColumnWidths",
  CARD_DASHBOARD_ORDER = "cardColOrder",
  CARD_DASHBOARD_VISIBILITY = "columnFilterOptions",
  CARD_DASHBOARD_FILTERS = "cardFilterOptions",  
  CARD_DASHBOARD_SORT = "cardSortOptions",
  CARD_BULKSTATUS_WIDTH = "cardColumnWidthsModal",
  CARD_REPACK_CURRENTBOXES = "currentBoxes",   
  CARD_REPACK_SELECTEDFORREPACK = "selectedForRepack",
  CARD_BULK_SHOWUPDATEMESSAGE = "showUpdateMessage",
  SHOW_SALES_FILTERS = "showSalesFilters",
  SHOWS_FILTERS = "showsFilters",
  DASHBOARD_SIDEBAR_OPEN = "dashboardSidebarOpen",
  TICKER_FILTERS = "tickerFilters",
  TICKER_SORT = "tickerSort",
  CARD_DEAR_SYNC_COLLISIONS = "cardDearSyncCollisions",
  CACHE_SETTINGS = "settingsCache"
}