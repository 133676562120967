import { RepackItem } from '../../../../Types/RepackItem';
import { ItemActionsType } from './types';
import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ItemActionsProps {
    item: RepackItem;
    actions: ItemActionsType;
  }
  
  const ItemActions: React.FC<ItemActionsProps> = ({ item, actions }) => {
    const hasCard = Boolean(item.repackCard);
    const hasTicket = Boolean(item.repackTicket);
  
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="secondary"
          onClick={() => actions.handleDeleteItem(item)}
          size="small"
        >
          <DeleteIcon />
        </Button>
        
        {hasCard && !hasTicket && (
          <Button
            color="primary"
            onClick={() => actions.handleAddTicketToCard(item)}
            size="small"
          >
            Add Ticket
          </Button>
        )}
        
        {hasCard && hasTicket && (
          <Button
            color="error"
            onClick={() => actions.handleRemoveTicketFromCard(item)}
            size="small"
          >
            Remove Ticket
          </Button>
        )}
      </Box>
    );
  };

  export default ItemActions;
  
  